<template>
  <div>
    <drawer :showDrawer="drawer" @changeDrawer="drawer=$event" />
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Application</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y rounded="lg">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip left>
            <template v-slot:activator="{ on: tooltip }">
              <v-avatar
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                color="primary"
                size="56"
              ></v-avatar>
            </template>
            <span>Im A ToolTip</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="item in profileItems"
            :key="item.title"
            @click="() => {}"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <!--  -->
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import drawer from "@/components/Dashboard/drawer";
export default {
  data() {
    return {
      drawer: false,
      profileItems: [
        { title: "Click Me1", icon: "mdi-account" },
        { title: "Click Me2", icon: "mdi-account" },
        { title: "Click Me3", icon: "mdi-account" },
        { title: "Click Me4", icon: "mdi-account" },
      ],
    };
  },
  components: {
    drawer,
  },
};
</script>
 
<style>
</style>


 